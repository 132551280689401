import { useAppSelector } from 'src/store/hooks';
import {IOrder} from "./order.types";
import {SelectItem} from "primereact/selectitem";

const useGetOrders = () =>
  useAppSelector(({ orders: { orders } }) => orders);

const useGetCurrOrder = (id: number) =>
  useAppSelector<IOrder | undefined>(({ orders: { orders } }) => {
    const findedOrder = orders.find((item) => item.id == id);
    if (findedOrder) {
      return findedOrder;
    } else {
      return undefined
    }
  });

const useGetNewOrderId = () =>
  useAppSelector(({ orders: { openedOrderId } }) => openedOrderId);

const useGetOpenedConstructionId = () =>
  useAppSelector(({ orders: { openedConstructionId } }) => openedConstructionId);

const useGetConstructions = () =>
  useAppSelector(({ orders: { constructions } }) => constructions);
const useGetCurrConstruction = (id: number) =>
  useAppSelector(({ orders: { constructions } }) => constructions.find((item) => item.id == id));

const useGetOpenedConstruction = () =>
  useAppSelector(({ orders: { openedConstruction } }) => openedConstruction);

const useOrderLoading = () =>
  useAppSelector(({ orders: { loading } }) => loading);
const useConstructionEstamate = () =>
  useAppSelector(({ orders: { openedConstructionEstimate } }) => openedConstructionEstimate);

const useOrdersError = () =>
  useAppSelector(({ orders: { err } }) => err);

const useStatusLabelForOrder = ({statusValue}: {statusValue: string}) => {
  return useAppSelector(({orders: {statuses}}) => statuses[statusValue])
}

const useStatusLabelForSelector = () => {
  return useAppSelector(({orders: {statuses}}) => {
    const replacedStatuses: SelectItem[] = Object.entries(statuses).reduce((acc, item) => {
      if (acc) {
        acc.push({
          label: item[1],
          value: item[0]
        })
      }
      return acc;
    }, [] as SelectItem[])
    return replacedStatuses
  })
}

const useDealerLabelForOrder = ({dealerValue}: {dealerValue: number}) => {
  return useAppSelector(({orders: {dealers}}) => dealers[dealerValue])
}

const useDealerLabelForSelector = () => {
  return useAppSelector(({orders: {dealers}}) => {
    const replacedDealers: SelectItem[] = Object.entries(dealers).reduce((acc, item) => {
      if (acc) {
        const dealer = JSON.parse(JSON.stringify(item[1]));
        acc.push({
          label: dealer.name,
          value: dealer.id
        })
      }
      return acc;
    }, [] as SelectItem[]).sort((a, b) => ((a.label&&b.label)?(a.label?.toUpperCase() < b.label?.toUpperCase() ? -1 : 1):-1));
    return replacedDealers
  })
}

export {
  useGetOrders,
  useOrderLoading,
  useOrdersError,
  useGetCurrOrder,
  useGetConstructions,
  useGetCurrConstruction,
  useGetNewOrderId,
  useGetOpenedConstructionId,
  useGetOpenedConstruction,
  useStatusLabelForOrder,
  useStatusLabelForSelector,
  useDealerLabelForOrder,
  useDealerLabelForSelector,
  useConstructionEstamate,
};
