import React, {useEffect, useState} from 'react';
import './GraphicForm.scss';
import {IParking, IReceivedConstructions, ISash} from "../../../store/order/order.types";
import PageTitle from "../../components/pageTitle/PageTitle";
import {RenderRadioGroup} from "../RenderForm/RenderRadioGroup";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import {
  radioSashDoorFields,
  radioSashDoorFieldsDirection,
  radioSashDoorFieldsOpening,
  radioSashDoorHandle,
  radioSashDoorHandleColor,
  radioSashDoorParkFields,
  radioSashFieldsLatchLeftPlace,
  radioSashFieldsLatchLeftType,
  radioSashFieldsLatchRightPlace,
  radioSashFieldsLatchRightType,
  // radioSashDoorFields,
  radioSashLockFields,
} from "../../../utils/inputFields";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {FloatLabel} from "primereact/floatlabel";
import {Chip} from "primereact/chip";
//import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {useAppDispatch} from "../../../store/hooks";
import {setOrderError} from "../../../store/user/user.slice";
import {reCalculate} from "../../../utils/functions";
import {useUserRole} from "../../../store/user/user.selectors";

const Cell = ({
  cellData,
  selected,
  changeDataCheckbox,
  changeData,
  isParkSide,
  direction,
  rotated,
  deleteCell,
  rotateCell,
  disabled
}: {
  deleteCell: any,
  rotateCell: any,
  cellData: ISash,
  isParkSide: boolean,
  direction: string,
  rotated: boolean,
  selected: boolean,
  changeDataCheckbox: (e: any) => void,
  changeData: (e: any) => void,
  disabled?: boolean,
}) => {
  const [w, sW] = useState(cellData.width ? cellData.width : '');

  return (
    <>
      <div className={`graphic-form__cell ${selected ? 'graphic-form__cell_active' : ''}`}>
        <div className={'graphic-form__button-container'}>
          <Chip label={''+cellData.sash_number} />
          <Button 
            icon={"pi pi-arrow-" + direction} 
            disabled={!rotated}
            severity={rotated?undefined:"secondary"}
            onClick={rotateCell}
          />
          <Button
            icon={'pi pi-times'}
            severity="danger"
            rounded text
            onClick={deleteCell}></Button>
        </div>
        <div className='flex flex-wrap gap-3 justify-content-center'>
          <Checkbox
            inputId={'sash_'+cellData.sash_number}
            value={cellData.sash_type === 'door' ? 'Дверь' : 'Створка'}
            checked={cellData.sash_type === 'door'}
            name={'sash_type'}
            disabled={disabled ? disabled : isParkSide}
            onChange={changeDataCheckbox}
          /> 
        </div>
        <FloatLabel>
          <InputText
            className={'graphic-form__cell-input'}
            id={'width'}
            name={'width'}
            disabled={disabled}
            type={'number'}
            value={w}
            onChange={(e) => {
              sW(e.target.value);
            }
            }
            onBlur={changeData}
          />
          <label className={'graphic-form__cell-input-placeholder'} htmlFor={'width'}>Ширина створки</label>
        </FloatLabel>
        { /* <div className={'graphic-form__cell-bottom-field'}>
          {
            cellData.width ? cellData.width : cellData.width_calculated
          }
        </div> */ }
        <div className={'graphic-form__bracing-container'}>
          <div className={'graphic-form__bracing-item'}>
            {
              cellData.latches.latch_left_type ?
                cellData.latches.latch_left_type === 'bottom'
                  ? (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/latch_bottom.png'} alt={''} />
                    </>
                  ) : (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/latch_end.png'} alt={''} />
                    </>
                  ) : null
            }
          </div>

          <div className={'graphic-form__bracing-item'}>
            {
              cellData.latches.latch_right_type ?
                cellData.latches.latch_right_type === 'bottom'
                  ? (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/latch_bottom.png'} alt={''} />
                    </>
                  ) : (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/latch_end.png'} alt={''} />
                    </>
                  ) : null
            }
          </div>
        </div>
        <div className={'graphic-form__bracing-container'}>
          <div className={'graphic-form__bracing-item'}>
            {
              cellData.latches.latch_left_type ?
                cellData.latches.latch_left_type === 'bottom'
                  ? (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/socket.png'} alt={''} />
                    </>
                  ) : (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/socket.png'} alt={''} />
                    </>
                  ) : null
            }
          </div>

          <div className={'graphic-form__bracing-item'}>
            {
              cellData.latches.latch_right_type ?
                cellData.latches.latch_right_type === 'bottom'
                  ? (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/socket.png'} alt={''} />
                    </>
                  ) : (
                    <>
                      <img className={'graphic-form__bracing-item-image'} src={'/static/socket.png'} alt={''} />
                    </>
                  ) : null
            }
          </div>
        </div>
        <div className={'graphic-form__cell-bottom-field'}>
          {
            cellData.width ? cellData.width : cellData.width_calculated
          }
        </div>
      </div>
    </>
  )
}

const SashForm = ({
  cellData,
  changeData,
  changeDataLatches,
  changeDoorData,
  arrLen,
  changeDataDoorCheckbox,
  changeHandleData,
  park,
  disabled
}: {
  cellData: ISash,
  changeData: (data: any) => void,
  changeDataLatches: (data: any) => void,
  changeDoorData: (data: any) => void,
  changeHandleData: (data: any) => void,
  arrLen: number,
  changeDataDoorCheckbox: (data: any) => void,
  park: IParking,
  disabled?: boolean,
}) => {
  const isParkDoor = cellData.sash_type == 'door'
    && ((cellData.sash_number == 1 && !!park.parking_left_type) || (cellData.sash_number == arrLen && !!park.parking_right_type));

  return (
    <>
      <div className={'create-order__radio-forms'}>
        <div className={'construction__profile-variant'}>
          <RenderRadioGroup
            handleChange={changeDataLatches}
            formObject={cellData}
            disabled={disabled}
            fields={radioSashFieldsLatchLeftType}
          />
          {
            cellData.latches.latch_left_type ? (
              <RenderRadioGroup
                handleChange={changeDataLatches}
                formObject={cellData}
                disabled={disabled}
                fields={radioSashFieldsLatchLeftPlace}
              />
            ) : null
          }
          <RenderRadioGroup
            handleChange={changeDataLatches}
            formObject={cellData}
            disabled={disabled}
            fields={radioSashFieldsLatchRightType}
          />
          {
            cellData.latches.latch_right_type ? (
              <RenderRadioGroup
                handleChange={changeDataLatches}
                formObject={cellData}
                disabled={disabled}
                fields={radioSashFieldsLatchRightPlace}
              />
            ) : null
          }
        </div>
        {
          cellData.sash_type === 'door' ? (
            <div className={'construction__profile-variant'}>
              {
                cellData.sash_type === 'door'
                  ? (
                    <>
                      <RenderRadioGroup disabled={disabled} handleChange={changeHandleData} formObject={cellData} fields={radioSashDoorHandle} />
                      <>
                        {
                          cellData.handle?.handle_size == 'big' ? (
                            <RenderRadioGroup disabled={disabled} handleChange={changeHandleData} formObject={cellData} fields={radioSashDoorHandleColor} />
                          ) : null
                        }
                        {
                          cellData.sash_type === 'door' ? (
                            <span>
                              <div className={'construction__radio-group-title'}>
                                Замок
                              </div>
                              <Checkbox
                                value={cellData.lock}
                                checked={cellData.lock}
                                disabled={disabled}
                                name={'lock'}
                                id={'lock'}
                                onChange={changeDataDoorCheckbox}
                              />
                            </span>
                          ) : null
                        }

                        {isParkDoor
                          ? (
                            <>
                              <span>
                                <div className={'construction__radio-group-title'}>
                                  Нижний доводчик
                                </div>
                                <Checkbox
                                  value={cellData.closer === 'bottom'}
                                  checked={cellData.closer === 'bottom'}
                                  disabled={disabled}
                                  name={'closer'}
                                  id={'closer'}
                                  onChange={changeDataDoorCheckbox}
                                />
                              </span>
                            </>
                          ) : null
                        }
                      </>
                    </>
                  )
                  : null
              }
              {
                cellData.sash_type === 'door'
                  ? (
                    <>
                      <RenderRadioGroup
                        disabled={disabled}
                        handleChange={changeDoorData}
                        formObject={cellData}
                        fields={radioSashDoorFields}
                      />
                      {
                        cellData.door?.door_type == 'porch' && !isParkDoor ? (
                          <RenderRadioGroup
                            disabled={disabled}
                            handleChange={changeDoorData}
                            formObject={cellData}
                            fields={radioSashDoorFieldsOpening}
                          />
                        ) : null
                      }
                      {
                        !isParkDoor ? (
                          <RenderRadioGroup
                            disabled={disabled}
                            handleChange={changeDoorData}
                            formObject={cellData}
                            fields={radioSashDoorFieldsDirection}
                          />
                        ) : null
                      }
                      {
                        (cellData.sash_number == 1 || cellData.sash_number == arrLen) ? (
                          <>
                            <RenderRadioGroup
                              disabled={disabled}
                              handleChange={changeData}
                              formObject={cellData}
                              fields={radioSashDoorParkFields}
                            />
                            {
                              cellData.lock_type === 'lock' ? (
                                <RenderRadioGroup
                                  disabled={disabled}
                                  handleChange={changeData}
                                  formObject={cellData}
                                  fields={radioSashLockFields}
                                />
                              ) : null
                            }
                          </>
                        ) : null
                      }
                    </>
                  )
                  : null
              }
            </div>
          ) : null
        }

      </div>
    </>
  );
}

const GraphicForm = (
  {
    formObject,
    changeSashData,
    setIsEdited,
    replaceSashes,
    rotateSashe
  }:{
    formObject: IReceivedConstructions,
    changeSashData: (data: ISash) => void,
    setIsEdited: (data: boolean) => void,
    replaceSashes: (data: ISash[]) => void,
    rotateSashe: (data:any) => void
  }) => {

  if (!formObject.sashes?.length) {
    return <></>
  }
  const dispatch = useAppDispatch();
  const parkVariantLeft = formObject.parking.parking_left_type === 'parallel' ? 'parallel' : 'perpendicular';
  const parkVariantRight = formObject.parking.parking_right_type === 'parallel' ? 'parallel' : 'perpendicular';

  const parkPlaceLeft = formObject.parking.parking_left_place === 'inside';
  const parkPlaceRight = formObject.parking.parking_right_place === 'inside';

  const [selectedCellIndex, setSelectedCellIndex] = useState<number>(0);
  // @ts-ignore
  const [selectedCell, setSelectedCell] = useState<ISash>(formObject.sashes[0]);

  const changeData = (e: any) => {
    setIsEdited(true);
    const {name, value} = e.target;
    setSelectedCell((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const changeDataLatches = (e: any) => {
    setIsEdited(true);
    const {name, value} = e.target;
    console.log(name, value);
    setSelectedCell((prevState) => {
      return {
        ...prevState,
        latches: {
          ...prevState.latches,
          [name]: value
        }
      }
    })
  }

  const changeCheckValue = (e: any) => {
    setIsEdited(true);
    const {checked, target: {name}} = e;
    setSelectedCell((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      if (name === 'sash_type' && checked) {
        newState.profile_type_right = 'porch';
        newState.profile_type_left = 'porch';
        newState.door = {
          door_type: 'porch',
          door_direction: 'left',
          door_opening: ''
        }
      } else {
        const prevSash = formObject.sashes?.find((item) => item.sash_number === newState.sash_number - 1);

        newState.profile_type_right = prevSash?.profile_type_left === 'porch' ? 'porch' : '';
        newState.profile_type_left = prevSash?.profile_type_left;

        newState.door = {
          door_type: null,
          door_direction: null,
          door_opening: null
        }
      }
      return {
        ...newState,
        [name]: name === 'sash_type' ?  checked ? 'door' : 'sash' : checked
      }
    });
  }

  // const calculateProfiles = (sashes: ISash[]) => {
  //   sashes = JSON.parse(JSON.stringify(sashes));
  //   const newSashes = sashes.map((item, index, arr) => {
  //     if (item.sash_type === 'door') {
  //
  //     }
  //   });
  //   console.log(newSashes);
  // }
  const changeDataDoorCheckbox = (e: any) => {
    setIsEdited(true);
    const {checked, target: {name, id}} = e;

    if (id !== name) {
      setSelectedCell((prevState: any) => {
        return {
          ...prevState,
          [id]: {
            ...prevState.handle,
            [name]: checked
          }
        }
      })
      return;
    }

    setSelectedCell((prevState: any) => {
      return {
        ...prevState,
        [name]: checked
      }
    })
  }

  const changeDoorData = (e: any) => {
    setIsEdited(true);
    const {name, value} = e.target;

    setSelectedCell((prevState: any) => {
      return {
        ...prevState,
        door: {
          ...prevState.door,
          [name]: value
        }
      }
    })
  }

  const changeHandleData = (e: any) => {
    setIsEdited(true);
    const {name, value} = e.target;

    setSelectedCell((prevState) => {
      if (prevState.handle) {
        return {
          ...prevState,
          handle: {
            ...prevState.handle,
            [name]: value
          }
        }
      }
      return prevState
    })
  }

  useEffect(() => {
    if (selectedCell && formObject.sashes) {
      console.log(selectedCell);
      changeSashData(selectedCell);
    }
  }, [selectedCell]);

  // useEffect(() => {
  //   if (formObject.sashes) {
  //     replaceSashes(reCalculate(formObject.sashes, formObject));
  //   }
  // }, [formObject.sashes]);

  const addSashToConstruction = ({sashes, prevSashNumber}:{sashes: ISash[] | undefined, prevSashNumber: number}) => {
    sashes = JSON.parse(JSON.stringify(sashes));
    if (!sashes) {
      dispatch(setOrderError('Этой ошибки быть не должно, обратитесь к кому нибудь'));
      return
    }

    const firstHalfList = sashes.slice(0, prevSashNumber);
    const secondHalfList = sashes.slice(prevSashNumber).map((item) => {
      item.sash_number++;
      return item;
    });

    const profile_type_left = firstHalfList[firstHalfList.length - 1].profile_type_right === 'porch' ? 'porch' : firstHalfList[firstHalfList.length - 1].profile_type_right === 'mother' ? 'father' : 'mother';
    const profile_type_right = secondHalfList[0].profile_type_left === 'porch' ? 'porch' : secondHalfList[0].profile_type_left === 'mother' ? 'father' : 'mother';
    

    const newSashe:ISash = {
      // @ts-ignore
      id: undefined,
      construction: formObject.id,
      sash_number: firstHalfList.length + 1,
      sash_type: 'sash',
      profile_type_left: profile_type_left,
      profile_type_right: profile_type_right,
      profiles_vertical_length: '',
      width_calculated: '0',
      glass_width: NaN,
      glass_height: NaN,
      closer: '',
      lock_type: '',
      latches: {
        latch_left_type: '',
        latch_left_place: '',
        latch_right_type: '',
        latch_right_place: '',
      },
      direction: firstHalfList[firstHalfList.length - 1].direction
    }

    const newSasheList = [
      ...firstHalfList,
      newSashe,
      ...secondHalfList
    ];
    const newSashes = reCalculate(newSasheList, formObject);
    replaceSashes(newSashes)
  }

  
  const rotateCell = ({sashesList, sashesNum}:{sashesList: ISash[] | undefined, sashesNum: number}) => {
    sashesList = JSON.parse(JSON.stringify(sashesList));
    if (!sashesList) {
      dispatch(setOrderError('Этой ошибки быть не должно, обратитесь к кому нибудь'));
      return
    }
    /*if (formObject.parking.parking_left_sashes_count !== null 
      && formObject.parking.parking_right_sashes_count !== null) {

      if(sashNumber<=formObject.parking.parking_left_sashes_count) {
        formObject.parking.parking_left_sashes_count--;
        formObject.parking.parking_right_sashes_count++;
      } else {
        formObject.parking.parking_left_sashes_count++;
        formObject.parking.parking_right_sashes_count--;
      }
    }*/
    console.log({sashesList, sashesNum});
    rotateSashe({sashesList, sashesNum});
  }

  const acceptRemoveSashFromConstruction =  ({sashes, sashNumber}:{sashes: ISash[] | undefined, sashNumber: number}) => {
    //console.log(sashes)
    
    sashes = JSON.parse(JSON.stringify(sashes));
    if (!sashes) {
      dispatch(setOrderError('Этой ошибки быть не должно, обратитесь к кому нибудь'));
      return
    }
    const firstHalfList = sashes.slice(0, sashNumber);
    const secondHalfList = sashes.slice(sashNumber).map((item) => {
      item.sash_number--;
      return item;
    });
    firstHalfList.splice(firstHalfList.length-1, 1);
    const newSasheList = [
      ...firstHalfList,
      ...secondHalfList
    ];
    const newSahses = reCalculate(newSasheList, formObject);
    replaceSashes(newSahses)
  }

  const removeSashFromConstruction = ({sashes, sashNumber}:{sashes: ISash[] | undefined, sashNumber: number}) => {
    confirmDialog({
      message: 'Вы уверены, что хотите удалить створку №' + sashNumber,
      header: 'Delete',//'Удаление створки',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => acceptRemoveSashFromConstruction({sashes, sashNumber})
    });
    //confirm();
  }

  const userRole = useUserRole();

  const disabled = userRole === 'administrator' || userRole === 'production_employee';

  return (
    <div className={'graphic-form'}>
      <ConfirmDialog />
      <PageTitle title={'Форма конструкции'} />

      <div className={'graphic-form__container'}>
        <div className={`graphic-form__park-container`}>
          {
            formObject.parking.parking_left_place || formObject.parking.parking_left_place === 'Наружу' ? (
              <div
                className={
                  `graphic-form__park graphic-form__park_${parkVariantLeft} 
                  ${parkPlaceLeft ? `graphic-form__park_${parkVariantLeft}_left-bottom` : `graphic-form__park_${parkVariantLeft}_left-top`}`}
              />
            ) : null
          }
          <div className={'graphic-form__park-line'} />
          {
            formObject.parking.parking_right_place || formObject.parking.parking_right_place === 'Внутрь' ? (
              <div
                className={
                  `graphic-form__park graphic-form__park_${parkVariantRight} 
                  ${parkPlaceRight ? `graphic-form__park_${parkVariantRight}_right-bottom` : `graphic-form__park_${parkVariantRight}_right-top`}`}
              />
            ) : null
          }
        </div>
        <div className={'graphic-form__cell-container'}>
          {formObject.sashes?.length && formObject.sashes.map((item, index, arr) => {
            if (formObject.sashes && formObject.sashes[index]) {
              const isParkSide =
                (!!formObject.parking.parking_right_place && index === arr.length - 1)
                || (!!formObject.parking.parking_left_place && index === 0) ;
              const direction = 
                (formObject.parking.parking_left_sashes_count !== null &&
                  formObject.parking.parking_left_sashes_count > index) ? 'left' : 'right';
              const rotated = 
                (formObject.parking.parking_left_sashes_count !== null &&
                 formObject.parking.parking_right_sashes_count !== null &&
                  (direction === 'right' && index === 0
                    || formObject.parking.parking_left_sashes_count === index
                    || formObject.parking.parking_left_sashes_count-1 === index
                  )
                ) ? true : false;
              
              console.log('GF: ' + index + " - " + formObject.sashes[index].direction + " - " + rotated)
              
              return (
                <div
                  className={'graphic-form__cell-wrapper'}
                  key={`${item.id}${index}`}
                  onClick={() => {
                    setSelectedCell(item);
                    setSelectedCellIndex(index);
                  }}>
                  <Cell
                    cellData={formObject.sashes[index]}
                    isParkSide={isParkSide}
                    direction={direction}
                    rotated={rotated}
                    disabled={disabled}
                    selected={selectedCellIndex === index}
                    changeDataCheckbox={changeCheckValue}
                    changeData={changeData}
                    deleteCell={() => removeSashFromConstruction({sashes: formObject.sashes, sashNumber: item.sash_number})}
                    rotateCell={() => rotateCell({sashesList: formObject.sashes, sashesNum: item.sash_number})}
                  />
                  {
                    index !== arr.length - 1 ? (
                      <Button
                        className={'button-add'}
                        disabled={disabled}
                        onClick={() => addSashToConstruction({sashes: formObject.sashes, prevSashNumber: item.sash_number})}
                        icon={'pi pi-plus'}></Button>
                    ) : null
                  }
                </div>
              )
            }
          })}
        </div>
      </div>
      {
        formObject.sashes?.length && formObject.sashes[selectedCellIndex] ? (
          <div className={'graphic-form__form-container'}>
            <SashForm
              park={formObject.parking}
              changeDoorData={changeDoorData}
              changeHandleData={changeHandleData}
              cellData={selectedCell}
              arrLen={formObject.sashes.length}
              disabled={disabled}
              changeData={changeData}
              changeDataLatches={changeDataLatches}
              changeDataDoorCheckbox={changeDataDoorCheckbox}
            />
          </div>
        ) : (
          <>

          </>
        )
      }
    </div>
  );
};

export default GraphicForm;
