import React /*, {useEffect , useState}*/ from 'react';
import {Checkbox} from "primereact/checkbox";
import PageTitle from "../pageTitle/PageTitle";
import {RenderFormFields} from "../RenderForm/RenderFormFields";
import {
  firstGroupFields,
  // firstGroupFieldsSashes1,
  firstGroupFieldsSashesLeft,
  firstGroupFieldsSashesRight,
  radioAssFields,
  radioColorFields,
  radioLeftParkFieldsPlace,
  radioLeftParkFieldsType,
  radioRightParkFieldsPlace,
  radioRightParkFieldsType
} from "../../../utils/inputFields";
import {RenderRadioGroup} from "../RenderForm/RenderRadioGroup";
import {IReceivedConstructions} from "../../../store/order/order.types";
// import {Checkbox} from "primereact/checkbox";

const ConstructionForm = ({
  formObject,
  handleChange,
  handleChangePark,
  // handleChangeParkSashCount,
  handleChangeAssembly,
  handleChangeColor,
  handleColorCheckbox,
  // isNew,
  disabled
}: {
  formObject: IReceivedConstructions,
  handleChange: (data: any) => void,
  handleChangePark: (data: any) => void,
  handleChangeParkSashCount: (data: any) => void,
  handleChangeProfiles: (data: any) => void,
  handleChangeAssembly: (data: any) => void,
  handleChangeColor: (data: any) => void,
  handleColorCheckbox: (data: any) => void,
  enabledButton: (data: any) => boolean,
  isEdited: boolean,
  isNew: boolean,
  disabled?: boolean,
  createConstruction: () => void
}) => {

  //const [slider, setSlider] = useState<any>(0);

  const left = formObject.parking.parking_left_sashes_count ? formObject.parking.parking_left_sashes_count : 0
  const right = formObject.parking.parking_right_sashes_count ? formObject.parking.parking_right_sashes_count : 0
  const ralActive = false
  console.log(left + ', ' + right)
  // const maxValueSlider = parseInt(`${left + right}`)
  /*useEffect(() => {
    if (maxValueSlider) {
      setSlider(left);
    }
  }, [maxValueSlider, formObject.parking.parking_left_sashes_count]);
  */
  return (
    <div>
      <PageTitle title={'Конструкция ' + formObject.construction_type} />
      <>
        <div className={'construction__form'}>
          <div className={'construction__container'}>
            <div className={'construction__basic-form-container'}>
              <RenderFormFields
                disabled={disabled}
                array={firstGroupFields}
                formObject={formObject}
                handleChange={handleChange}
              />
            </div>
            <div className={'construction__radio-forms-row'}>
              <div className={'construction__basic-form-container'}>
                <div className={'construction__profile-variant'}>
                  <Checkbox
                    inputId={'ral_colored'}
                    value={ralActive}
                    checked={false}
                    name={'ral_colored'}
                    disabled={disabled}
                    onChange={handleColorCheckbox}
                  />
                  <label htmlFor='ral_colored'>Покраска Ral</label>
                  <RenderRadioGroup
                    handleChange={handleChangeColor}
                    formObject={formObject}
                    disabled={disabled}
                    fields={radioColorFields}
                  />
                </div>
              </div>
              <div className={'construction__basic-form-container'}>
                <div className={'construction__profile-variant'}>
                  <RenderRadioGroup
                    handleChange={handleChangeAssembly}
                    formObject={formObject}
                    disabled={disabled}
                    fields={radioAssFields}
                  />
                </div>
              </div>
            </div>
            { /* <div className={'construction__basic-form-container'}>
              <RenderFormFields
                disabled={disabled}
                array={firstGroupFieldsSashes1}
                formObject={formObject}
                handleChange={handleChangePark}
              />
            </div> */ } 
            <div className={'create-order__radio-forms'}>
              <div className={'construction__radio-forms-row'}>
                <div className={'construction__park-variant'}>
                  Левая парковка
                  <RenderFormFields
                    disabled={disabled}
                    array={firstGroupFieldsSashesLeft}
                    formObject={formObject}
                    handleChange={handleChangePark}
                  />
                  { formObject.parking.parking_left_sashes_count !== null &&
                    formObject.parking.parking_left_sashes_count > 0 ? 
                    (
                      <div className={'construction__park-variant_form'}>
                        <RenderRadioGroup
                          handleChange={handleChangePark}
                          disabled={disabled}
                          formObject={formObject}
                          fields={radioLeftParkFieldsType}
                        />
                        {
                          formObject.parking.parking_left_type ? (
                            <RenderRadioGroup
                              handleChange={handleChangePark}
                              formObject={formObject}
                              disabled={disabled}
                              fields={radioLeftParkFieldsPlace}
                            />
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                </div>
                <div className={'construction__park-variant'}>
                  Правая парковка
                  <RenderFormFields
                    disabled={disabled}
                    array={firstGroupFieldsSashesRight}
                    formObject={formObject}
                    handleChange={handleChangePark}
                  />
                  { formObject.parking.parking_right_sashes_count !== null &&
                    formObject.parking.parking_right_sashes_count > 0 ? 
                    (
                      <div className={'construction__park-variant_form'}>
                        <RenderRadioGroup
                          handleChange={handleChangePark}
                          formObject={formObject}
                          disabled={disabled}
                          fields={radioRightParkFieldsType}
                        />
                        {
                          formObject.parking.parking_right_type ? (
                            <RenderRadioGroup
                              handleChange={handleChangePark}
                              formObject={formObject}
                              disabled={disabled}
                              fields={radioRightParkFieldsPlace}
                            />
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                </div>
              </div>

              {/*<div className={'construction__checkbox-container'}>*/}
              {/*  <div>*/}
              {/*    Две парковки*/}
              {/*  </div>*/}
              {/*</div>*/}

            </div>

          </div>
        </div>
      </>
      <div className={'construction__button-container'}>

        {/*{*/}
        {/*  formObject.sashes*/}
        {/*    ? (*/}
        {/*      <Button*/}
        {/*        className={'construction__button'}*/}
        {/*        icon={'pi pi-arrow-right'}*/}
        {/*        iconPos={'right'}*/}
        {/*        disabled={!enabledButton(formObject) || isEdited}*/}
        {/*        onClick={goToSashes}>*/}
        {/*        Створки*/}
        {/*      </Button>*/}
        {/*    )*/}
        {/*    : null*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default ConstructionForm;
