import { fetchOrders } from 'src/services/orders/fetchOrders';
import {fetchCreateOrder} from "./createOrder";
import {getConstructions} from "./getConstructions";
import {IConstruction, ICreateOrderReq, IOrder, IReceivedConstructions} from "../../store/order/order.types";
import {fetchOrderById} from "./fetchOrderById";
import {fetchCreateConstruction} from "./fetchCreateConstruction";
import {fetchConstructionById} from "./fetchConstructionById";
import {putCreateOrderById} from "./putOrderById";
import {putConstruction} from "./putConstruction";
import {deleteOrderById} from "./deleteOrderById";
import {fetchStatusValues} from "./fetchStatusValues";
import {fetchDealerValues} from "./fetchDealerValues";
import {calculateConstruction} from "./calculateConstruction";
import {fetchEstimates} from "./fetchEstimates";

class OrderServices {
  putOrderByIdService = (reqData: IOrder) => putCreateOrderById(reqData);
  deleteOrderByIdService = (reqData: number) => deleteOrderById(reqData);
  putConstructionService = (reqData: IReceivedConstructions) => putConstruction(reqData);
  calculateConstructionService = (reqData: IReceivedConstructions) => calculateConstruction(reqData);
  fetchEstimatesService = (reqData: number) => fetchEstimates({constructionId: reqData});
  fetchOrdersService = (reqData: string | void) => fetchOrders(reqData);
  fetchOrderByIdService = (reqData: {orderId: number}) => fetchOrderById(reqData);
  createOrderService = (reqData: ICreateOrderReq) => fetchCreateOrder(reqData);
  fetchConstructionByIdService = (reqData: { id: number }) => fetchConstructionById(reqData);
  createConstructionService = (reqData: IConstruction) => fetchCreateConstruction(reqData);
  getConstructionsService = (orderId: number) => getConstructions({orderId: orderId});
  fetchStatusValuesService = () => fetchStatusValues();
  fetchDealerValuesService = () => fetchDealerValues();
}

const orderServices = new OrderServices();

export { orderServices };
