import React, {ChangeEvent, useEffect, useState} from 'react';

// import {OrderComponent} from '../components/orderComponent/OrderComponent';

import {useGetNewOrderId, useGetOrders, useOrderLoading, useStatusLabelForOrder} from '../../store/order/order.selectors';
import {
  createOrderAction, deleteOrderAction,
  fetchOrdersAction
} from '../../store/order/order.actions';
import {useAppDispatch} from '../../store/hooks';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {FloatLabel} from "primereact/floatlabel";

import PageTitle from '../components/pageTitle/PageTitle';
import {Button} from 'primereact/button';

import './AISModule.scss';
import {useGetUser, useUserRole} from "../../store/user/user.selectors";
import {useHistory} from "react-router-dom";
import {Order} from "../../utils/constants";
import {DataTable, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import {formatDate} from "../../utils/functions";
import {setOpenedOrder} from "../../store/order/order.slice";

const AISModule = () => {
  const [createOrder, setCreateOrder] = useState<boolean>(false);
  const [nameCustomer, setNameCustomer] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortColumnType, setSortColumnType] = useState<string | undefined>(undefined);

  const list = useGetOrders();
  const orderLoading = useOrderLoading();
  const user = useGetUser();
  const userRole = useUserRole();
  const newOrderId = useGetNewOrderId();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const disabled = userRole == 'administrator' || userRole === 'production_employee';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameCustomer(e.target.value);
  }

  const createReq = () => {
    dispatch(createOrderAction({
      customer: '____',
      dealer_id: user.dealer_id ? user.dealer_id : undefined,
      order_number: Math.floor(Math.random() * 1000000),
    }));
  }

  const getFiltredOrders = (params: DataTableSortEvent) => {
    console.log(params);
    setSortColumn(params.multiSortMeta ? params.multiSortMeta[0].field : params.sortField);
    if (sortColumnType == undefined || sortColumnType === 'multiple') {
      setSortColumnType('single');
    } else if (sortColumnType === 'single') {
      setSortColumnType('multiple');
    }
    dispatch(fetchOrdersAction(`${sortColumnType === undefined || sortColumnType === 'single' ? '' : '-'}${params.multiSortMeta ? params.multiSortMeta[0].field : params.sortField}`));
  }

  useEffect(() => {
    if (newOrderId) {
      history.push(Order.replace(':orderId', `${newOrderId}`));
    }
  }, [newOrderId])

  const actionBodyTemplate = (rowData: any) => {
    console.log(rowData);
    return (
      <React.Fragment>
        <Button icon="pi pi-angle-double-right" outlined className="mr-2" style={{marginRight: '10px'}} onClick={() => dispatch(setOpenedOrder(rowData.id))} />
        <Button icon="pi pi-trash" outlined severity="danger" onClick={() => {
          dispatch(setOpenedOrder(-1));
          dispatch(deleteOrderAction(rowData.id));
        }} />
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className={'ais-module__top-container'}>
        <PageTitle title={'Список заявок'} />
        <Button
          className={'ais-module__top-button'}
          iconPos={'right'}
          icon={'pi pi-plus'}
          disabled={disabled}
          label={'Новая заявка'}
          onClick={createReq}
        />
      </div>
      <Dialog
        visible={createOrder}
        modal
        onClick={() => {if (!createOrder) return; setCreateOrder(false); }}
        onHide={() => {if (!createOrder) return; setCreateOrder(false); }}
        content={() => (
          <div className="name-form" onClick={(e) => e.stopPropagation()}>
            <FloatLabel>
              <InputText
                className={'input'}
                name={'customer'}
                id={'customer'}
                type={"text"}
                disabled={disabled}
                value={nameCustomer}
                onChange={handleChange}
              />
              <label htmlFor={'customer'}>Имя заказчика</label>
            </FloatLabel>
            <Button
              iconPos={'right'}
              icon={'pi pi-plus'}
              label={'Создать заявку'}
              disabled={disabled}
              onClick={createReq}
            />
          </div>
        )}
      ></Dialog>

      {Array.isArray(list) ? (
        <div className={'ais-module__order-list'}>
          <DataTable
            loading={orderLoading}
            onSort={getFiltredOrders}
            sortField={sortColumn}
            sortMode={'multiple'}
            value={list}
            tableStyle={{ minWidth: '50rem' }}>
            <Column
              field="id"
              header="№"
              align={"center"}
              alignHeader={"center"}
              sortable
              style={{ width: '10%' }}>
            </Column>
            <Column
              field="date_create"
              body={(rowData: any) => formatDate(rowData.date_create)}
              header="Дата создания"
              align={"center"}
              alignHeader={"center"}
              sortable
              style={{ width: '20%' }}>
            </Column>
            <Column field="customer" header="Заказчик" sortable style={{ width: '20%' }}></Column>
            <Column field="dealer_name" header="Дилер" sortable style={{ width: '20%' }}></Column>
            <Column 
              field="status" 
              body={(rowData: any) => useStatusLabelForOrder({statusValue: rowData.status})}
              header="Статус" 
              align={"center"}
              alignHeader={"center"}
              sortable 
              style={{ width: '20%' }}>
            </Column>
            <Column field="" header="" body={actionBodyTemplate} style={{ width: '20%' }}></Column>
          </DataTable>
        </div>
      ) : null}

    </div>

  );
};

export default AISModule;
