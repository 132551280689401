import React, {useEffect, useState} from 'react';
import {
  useGetConstructions,
  useGetCurrOrder,
  useOrdersError,
  useStatusLabelForSelector,
  useDealerLabelForSelector
} from "../../store/order/order.selectors";
import {useHistory, useParams} from "react-router-dom";
import {
  AIS_Route,
  Order_Construction
} from "../../utils/constants";
import {useAppDispatch} from "../../store/hooks";
import {
  deleteOrderAction,
  fetchOrderByIdAction,
  putOrderByIdAction
} from "../../store/order/order.actions";
import {Button} from "primereact/button";
import {RenderFormFields} from "../components/RenderForm/RenderFormFields";
import {orderFormFields} from "../../utils/inputFields";
import {IOrder} from "../../store/order/order.types";

import './OrderPage.scss';
import {setOpenedOrder} from "../../store/order/order.slice";
import {Dropdown} from "primereact/dropdown";
import {formatDate} from "../../utils/functions";
import {useUserRole} from "../../store/user/user.selectors";
import * as process from "process";

const initState:IOrder = {
  id: 0,
  dealer_id: 0,
  dealer_name: '',
  date_create: '',
  status: '',
  customer: '',
  order_number: '',
}

const OrderPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams<any>();

  const currOrder = useGetCurrOrder(params.orderId);
  const err = useOrdersError();
  const [order, setOrder] = useState<IOrder>(initState);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const constructions = useGetConstructions();
  const statuses = useStatusLabelForSelector();
  const dealers = useDealerLabelForSelector();
  const history = useHistory();

  const userRole = useUserRole()

  useEffect(() => {
    if (!params.orderId) {//По логике orderId будет в любом случае
      history.push(AIS_Route);
      return
    }
    dispatch(fetchOrderByIdAction({orderId: params.orderId}));
  }, []);

  useEffect(() => {
    if (err) {
      history.push(AIS_Route);
    }
  }, [err]);

  const newConstructionClick = () => {
    history.push(Order_Construction.replace(':id', 'new').replace(':orderId', params.orderId));
  }

  useEffect(() => {
    if (currOrder?.id) {
      const middlewareOrder = {...currOrder, date_create: formatDate(currOrder.date_create)}
      setOrder(middlewareOrder);
    } else if (!params.orderId) {
      history.push(AIS_Route);
    }
  }, [currOrder]);

  const handleChange = (e: any) => {
    setIsEdited(true);
    const {name, value} = e.target;
    setOrder((prevState) => {
      return {...prevState, [name]: value}
    })
  }

  const saveOrder = () => {
    dispatch(putOrderByIdAction(order));
    setIsEdited(false);
  }

  const deleteOrder = async () => {
    history.push(AIS_Route);
    dispatch(setOpenedOrder(-1));
    dispatch(deleteOrderAction(order.id));
  }

  //const disabled = !(userRole === 'administrator' || userRole === 'production_employee');
  const customerEnabled = (userRole === 'administrator' || userRole === 'office_employee' || userRole === 'dealer');
  const statusEnabled = (userRole === 'administrator' || userRole === 'office_employee');
  const dealerEnabled = (userRole === 'administrator' || userRole === 'office_employee');
  const deleteEnabled = (userRole === 'administrator' || userRole === 'office_employee' || userRole === 'dealer');
  const constructEnabled = (userRole === 'administrator' || userRole === 'office_employee' || userRole === 'dealer');
  console.log('order: ' + order.dealer_id);
  // console.log('disabled: ' + disabled);

  return (
    <>
      <div className={'order-page__container'}>
        <div className={'order-page__basic-form-container'}>
          <RenderFormFields 
            disabled={!customerEnabled} 
            array={orderFormFields} 
            formObject={order} 
            handleChange={handleChange} />
          <Dropdown
            value={order.dealer_id}
            onChange={handleChange}
            options={dealers}
            disabled={!dealerEnabled}
            name={'dealer_id'}
            placeholder="Дилер"
            className="w-full md:w-14rem"
          />
          <Dropdown
            value={order.status}
            onChange={handleChange}
            options={statuses}
            disabled={!statusEnabled}
            name={'status'}
            placeholder="Статус заявки"
            className="w-full md:w-14rem"
          />
          <Button 
            onClick={saveOrder} 
            disabled={!isEdited /*|| disabled*/} 
            icon="pi pi-save"
            severity="success"
            className={'order-page__order-b1utton'}>
            {/*Сохранить*/}
          </Button>
          
          {deleteEnabled ? null : (
            <Button
              icon={'pi pi-trash'}
              onClick={deleteOrder}
              severity={'danger'}
              className={'order-page__order-button'}>
              {/*Удалить*/}
            </Button>
          )}

          { (constructions && constructions.length) ? (
            <Button 
              icon="pi pi-download" 
              onClick={() =>  window.open(`${process.env.REACT_APP_BASE_URL}/estimate_export_order/${order.id}/`, '_blank')}/>
            /*Скачать смету*/
          ) : null }
          { (constructions && constructions.length) ? (
            <Button 
              icon="pi pi-print" 
              onClick={() =>  window.open(`${process.env.REACT_APP_BASE_URL}/order_to_pdf/${order.id}/`, '_blank')}/>
            /*Печатная форма заказа*/
          ) : null }
        </div>
      </div>
      <div>
        <div className={'order-page__construction-title'}>
          Конструкции:
        </div>
        <div className={'order-page__construction-container'}>
          <div className={'order-page__construction-item'}>
            <Button
              className={'order-page__construction-button'}
              size="large"
              outlined
              severity="secondary"
              disabled={!constructEnabled}
              onClick={newConstructionClick}
            >
              F4
            </Button>
          </div>
          {
            constructions && constructions.length
              ? (
                constructions.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={'order-page__construction-item'}
                      onClick={() => history.push(Order_Construction.replace(':id', `${item.id}`).replace(':orderId', params.orderId))}>
                      <div>ID конструкции: {item.id}</div>
                      <div>Количество створок: {item.sashes_count}</div>
                      <div>Тип конструкции: {item.construction_type}</div>
                      <div>Высота конструкции: {item.height}</div>
                      <div>Ширина конструкции: {item.width}</div>
                    </div>
                  )
                })
              )
              : null
          }
        </div>
      </div>
    </>
  );
};

export default OrderPage;
