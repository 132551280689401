import {axiosInstance as axios} from 'src/services/api';

const fetchDealerValues = async (): Promise<{[key: string]: string}> => {
  try {
    const { data } = await axios.get<{[key: string]: string}>('/dealers/');
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchDealerValues };
