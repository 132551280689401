import { createSlice } from '@reduxjs/toolkit';

import {
  calculateConstructionAction,
  createConstructionAction,
  createOrderAction,
  deleteOrderAction,
  fetchConstructionByIdAction,
  fetchConstructionsAction, fetchEstimatesAction,
  fetchOrderByIdAction,
  fetchOrdersAction,
  fetchStatusValuesAction,
  fetchDealerValuesAction,
  putOrderByIdAction,
} from 'src/store/order/order.actions';
import type {IOrderState} from 'src/store/order/order.types';
import { SliceNames } from 'src/store/enums';

const initialState: IOrderState = {
  orders: [],
  constructions: [],
  statuses: {},
  dealers: {},
  openedOrderId: undefined,
  openedConstructionId: undefined,
  loading: false,
  err: ''
};

const slice = createSlice({
  initialState,
  name: SliceNames.ORDERS,
  reducers: {
    setOpenedOrder: (state, {payload}: {payload: number}) => {
      if (payload > 0) {
        state.openedOrderId = payload;
      } else {
        state.openedOrderId = undefined;
      }
    },
    setOpenedConstruction: (state, {payload}: {payload: number}) => {
      state.openedConstructionId = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchOrdersAction.fulfilled,
        (state, { payload: { orders } }) => {
          state.loading = false;

          state.orders = orders;
          return;
        },
      )
      .addCase(fetchOrdersAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        fetchOrdersAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        createOrderAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.orders.push(payload);

          state.openedOrderId = payload.id;
          return;
        },
      )
      .addCase(createOrderAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        createOrderAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchConstructionsAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;

          state.constructions = payload;
          return;
        },
      )
      .addCase(fetchConstructionsAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        fetchConstructionsAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchOrderByIdAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.constructions = payload.constructions;
          if (payload.id) {
            const finded = state.orders.find((item) => item.id === payload.id)
            if (!finded) {
              payload.constructions = [];
              state.orders = [...state.orders, payload]
            }
          }
          return;
        },
      )
      .addCase(fetchOrderByIdAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        fetchOrderByIdAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        createConstructionAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.constructions = [...state.constructions, payload];
          state.openedConstructionId = payload.id;
          return;
        },
      )
      .addCase(createConstructionAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        createConstructionAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchConstructionByIdAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          const finded = state.constructions.find((item) => item.id == payload.id);
          if (!finded)
            state.constructions = [...state.constructions, payload];

          state.openedConstruction = payload;
          return;
        },
      )
      .addCase(fetchConstructionByIdAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        fetchConstructionByIdAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        putOrderByIdAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          const index = state.orders.findIndex((item) => item.id == payload.id);
          state.orders[index] = payload
          return;
        },
      )
      .addCase(putOrderByIdAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        putOrderByIdAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchEstimatesAction.fulfilled,
        (state, { payload }) => {
          state.openedConstructionEstimate = payload;
          return;
        },
      )
      .addCase(fetchEstimatesAction.pending, (state) => {
        state.loading = true;
        state.err = '';
      })
      .addCase(
        fetchEstimatesAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        deleteOrderAction.fulfilled,
        (state, { payload }) => {
          state.openedOrderId = undefined;
          state.loading = false;

          const deleted = state.orders.findIndex(item => item.id === payload)
          if (deleted >= 0) {
            const filtered = state.orders.filter((item) => item.id !== payload);
            state.orders = filtered;
          }
          return;
        },
      )
      .addCase(deleteOrderAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(
        deleteOrderAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchStatusValuesAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.statuses = payload;
          return;
        },
      )
      .addCase(fetchStatusValuesAction.pending, (state) => {
        state.loading = true;
        state.err = '';
      })
      .addCase(fetchStatusValuesAction.rejected, (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
        state.err = message;
      })
      .addCase(
        fetchDealerValuesAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.dealers = payload;
          return;
        },
      )
      .addCase(fetchDealerValuesAction.pending, (state) => {
        state.loading = true;
        state.err = '';
      })
      .addCase(fetchDealerValuesAction.rejected, (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
        state.err = message;
      })
      .addCase(
        calculateConstructionAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.openedConstruction = payload;
          return;
        },
      )
      .addCase(calculateConstructionAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(calculateConstructionAction.rejected, (state, error) => {
        console.log(error)
        state.err = 'Что-то пошло не так';
      })
  },
});

export const { reducer: orderReducer } = slice;
export const {setOpenedOrder, setOpenedConstruction} = slice.actions;
