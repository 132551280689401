import {IFormRadioItem, IFormTextItem} from "../pages/ConstructioinPage/ConstructionPage";

export const firstGroupFields: IFormTextItem[] = [
  /*{
    label: 'Тип конструкции',
    id: 'construction_type',
    type: 'text',
    disabled: true,
  },*/
  {
    label: 'Ширина',
    id: 'width',
    type: 'number',
    min: 1000
  },
  {
    label: 'Высота',
    id: 'height',
    type: 'number',
    min: 1000
  },
  {
    label: 'Кол-во конструкций',
    id: 'amount',
    type: 'number',
    min: 1
  },
];

export const firstGroupFieldsSashes1: IFormTextItem[] = [
  {
    label: 'Кол-во в левой парковке',
    id: 'parking_left_sashes_count',
    object: 'parking',
    type: 'number',
    min: 0
  },
  {
    label: 'Кол-во в правой парковке',
    id: 'parking_right_sashes_count',
    object: 'parking',
    type: 'number',
    min: 0
  },
]

export const firstGroupFieldsSashesLeft: IFormTextItem[] = [
  {
    label: 'Кол-во в левой парковке',
    id: 'parking_left_sashes_count',
    object: 'parking',
    type: 'number',
    min: 0
  },
]
export const firstGroupFieldsSashesRight: IFormTextItem[] = [
  {
    label: 'Кол-во в правой парковке',
    id: 'parking_right_sashes_count',
    object: 'parking',
    type: 'number',
    min: 0
  },
]

export const firstGroupFieldsSashes1Disabled: IFormTextItem[] = [
  {
    label: 'Кол-во створок в левой парковке',
    id: 'parking_left_sashes_count',
    object: 'parking',
    type: 'number',
    disabled: true,
    min: 0,
  },
  {
    label: 'Кол-во створок в правой парковке',
    id: 'parking_right_sashes_count',
    object: 'parking',
    type: 'number',
    disabled: true,
    min: 0,
  },
];

export const orderFormFields: IFormTextItem[] = [
  /*{
    label: 'Номер заявки',
    id: 'id',
    type: 'text',
    disabled: true
  },*/
  {
    label: 'Дата заявки',
    id: 'date_create',
    type: 'text',
    disabled: true
  },
  {
    label: 'Заказчик',
    id: 'customer',
    type: 'text'
  },
]

export const radioLeftParkFieldsType: IFormRadioItem[] = [
  {
    id: 'parking_left_type',
    type: 'parking',
    name: 'Тип парковки',
    variants: {
      'Перпендикулярная': 'perpendicular',
      'Параллельная': 'parallel',
      /*'Ничего': null,*/
    }
  },
]


export const radioRightParkFieldsType: IFormRadioItem[] = [
  {
    id: 'parking_right_type',
    type: 'parking',
    name: 'Тип парковки',
    variants: {
      'Перпендикулярная': 'perpendicular',
      'Параллельная': 'parallel',
      /*'Ничего': null,*/
    }
  },
]

export const radioLeftParkFieldsPlace: IFormRadioItem[] = [
  {
    id: 'parking_left_place',
    type: 'parking',
    name: 'Размещение парковки',
    variants: {
      'Внутрь': 'inside',
      'Наружу': 'outside',
      /*'Ничего': null,*/
    }
  },
]


export const radioRightParkFieldsPlace: IFormRadioItem[] = [
  {
    id: 'parking_right_place',
    type: 'parking',
    name: 'Размещение парковки',
    variants: {
      'Внутрь': 'inside',
      'Наружу': 'outside',
      /*'Ничего': null,*/
    }
  },
]

//
// export const radioProfileFields: IFormRadioItem[] = [
//   {
//     id: 'profile_start_type',
//     type: 'profiles',
//     name: 'Тип профиля в начале',
//     variants: [
//       'Мама (H)',
//       'Папа (П)',
//       'Притвор (h)'
//     ]
//   },
//   {
//     id: 'profile_end_type',
//     type: 'profiles',
//     name: 'Тип профиля в конце',
//     variants: [
//       'Мама (H)',
//       'Папа (П)',
//       'Притвор (h)'
//     ]
//   }
// ]

export const radioAssFields: IFormRadioItem[] = [
  {
    id: 'assembly',
    type: '',
    name: 'Сборка',
    variants: {
      'Полуфабрикат (без стекла, без сборки)': 'semiproduct',
      'Наше стекло': 'performerGlass',
      'Стекло заказчика': 'customerGlass',
    }
  },
]

export const radioColorFields: IFormRadioItem[] = [
  {
    id: 'painting',
    type: '',
    name: 'Цвет',
    variants: {
      'Глянец': 'gloss',
      'Матовое': 'matte'
    }
  },
]

export const radioSashFieldsFirst: IFormRadioItem[] = [
  // {
  //   id: 'sash_type',
  //   type: '',
  //   name: 'Тип створки',
  //   variants: [
  //     'Створка',
  //     'Дверь',
  //     'Глухая створка'
  //   ]
  // },
  // {
  //   id: 'profile_type_left',
  //   type: '',
  //   name: 'Тип левого профиля',
  //   variants: [
  //     'Мама (H)',
  //     'Папа (П)',
  //     'Притвор (h)'
  //   ]
  // },
  // {
  //   id: 'closer',
  //   type: '',
  //   name: 'Доводчик',
  //   variants: [
  //     'Нижний (притвор)',
  //     'Верхний',
  //     'Без доводчика (фланец с осью + ответка в базу)'
  //   ]
  // },
  // {
  //   id: 'profile_type_right',
  //   type: '',
  //   name: 'Тип правого профиля',
  //   variants: [
  //     'Мама (H)',
  //     'Папа (П)',
  //     'Притвор (h)'
  //   ]
  // },
]

export const radioSashDoorHandle: IFormRadioItem[] = [
  {
    id: 'handle_place',
    type: 'handle',
    name: 'Раположение ручки',
    variants: {
      'Изнутри': 'inside',
      'Снаружи': 'outside',
    }
  },
  {
    id: 'handle_size',
    type: 'handle',
    name: 'Размер ручки',
    variants: {
      'Большая': 'big',
      'Маленькая': 'small',
    }
  },
]


export const radioSashDoorHandleColor: IFormRadioItem[] = [
  {
    id: 'handle_color',
    type: 'handle',
    name: 'Цвет ручки',
    variants: {
      'Глянец': 'gloss',
      'Матовое': 'matte',
      'Без цвета': 'stainless',
    }
  },
]

export const radioSashDoorFields: IFormRadioItem[] = [
  {
    id: 'door_type',
    type: 'door',
    name: 'Тип двери',
    variants: {
      'Маятниковая': 'pendulum',
      'С притвором': 'porch',
    }
  },
  // {
  //   id: 'door_type',
  //   type: 'door',
  //   name: 'Тип двери',
  //   variants: {
  //     'Маятниковая': 'pendulum',
  //     'С притвором': 'porch',
  //   }
  // },
]

export const radioSashDoorFieldsOpening: IFormRadioItem[] = [
  {
    id: 'door_opening',
    type: 'door',
    name: 'Открывания двери',
    variants: {
      'Внутрь': 'inside',
      'Наружу': 'outside',
    }
  },
]

export const radioSashDoorFieldsDirection: IFormRadioItem[] = [
  {
    id: 'door_direction',
    type: 'door',
    name: 'Направление двери',
    variants: {
      'Левая': 'left',
      'Правая': 'right',
    }
  },
]

export const radioSashDoorParkFields: IFormRadioItem[] = [
  {
    id: 'lock_type',
    type: '',
    name: 'Тип закрывашки',
    variants: {
      'Замок': 'lock',
      'Защелка': 'latch',
    }
  },
]

export const radioSashLockFields: IFormRadioItem[] = [
  {
    id: 'latch_place',
    type: '',
    name: 'Ручка',
    variants: {
      'Внутри': 'inside',
      'Снаружи': 'outside',
    }
  },
]

export const radioSashFieldsLatchLeftType: IFormRadioItem[] = [
  {
    id: 'latch_left_type',
    type: 'latches',
    name: 'Тип левой защелки',
    variants: {
      'Защелка торцевая': 'end' ,
      'Защелка нижняя': 'bottom',
      'Нет': null,
    }
  },
]

export const radioSashFieldsLatchLeftPlace: IFormRadioItem[] = [
  {
    id: 'latch_left_place',
    type: 'latches',
    name: 'Положение левой защелки',
    variants: {
      'Внутри': 'inside' ,
      'Снаружи': 'outside'
    }
  },
]

export const radioSashFieldsLatchRightType: IFormRadioItem[] = [
  {
    id: 'latch_right_type',
    type: 'latches',
    name: 'Тип правой защелки',
    variants: {
      'Защелка торцевая': 'end' ,
      'Защелка нижняя': 'bottom',
      'Нет': null,
    }
  },
]

export const radioSashFieldsLatchRightPlace: IFormRadioItem[] = [
  {
    id: 'latch_right_place',
    type: 'latches',
    name: 'Положение правой защелки',
    variants: {
      'Внутри': 'inside' ,
      'Снаружи': 'outside'
    }
  },
]

export const sashFormFields: IFormTextItem[] = [
  {
    label: 'Длина профиля',
    id: 'profiles_vertical_length',
    type: 'text'
  },
  {
    label: 'Ширина створки',
    id: 'width',
    type: 'text'
  },
  {
    label: 'Ширина стекла',
    id: 'glass_width',
    type: 'number'
  },
  {
    label: 'Высота стекла',
    id: 'glass_height',
    type: 'number'
  },
]
