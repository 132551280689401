import React, {useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';

import {FloatLabel} from 'primereact/floatlabel';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';

import './AuthPage.scss';
import {useAppDispatch} from "../../store/hooks";
import {
  fetchAuthAction,
  fetchCreateDealerAction,
  fetchCreateUserAction,
} from "../../store/user/user.actions";
import {useRegisterDataDealerId} from "../../store/user/user.selectors";
import {Dropdown} from "primereact/dropdown";

const AuthForm = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    history.push('#login');
  }, []);

  const onClick = () => {
    dispatch(fetchAuthAction({username: login, password}));
  }

  const keyHandler = (event: any) => {
    if (event.key == 'Enter') {
      onClick();
    }
  }

  // useEffect(() => {
  //   window.addEventListener('keydown', (e) => {
  //     console.log(e.key);
  //     if (e.key == 'Enter') {
  //       onClick();
  //     }
  //   });
  //   return window.removeEventListener('keydown', (e) => {
  //     console.log(e.key);
  //     if (e.key == 'Enter') {
  //       onClick();
  //     }
  //   });
  // }, []);

  return (
    <>
      <div className={'auth__form p-fluid'} onKeyUp={keyHandler}>
        <FloatLabel>
          <InputText
            id='login'
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <label htmlFor='login'>Логин</label>
        </FloatLabel>
        <FloatLabel>
          <Password
            inputId='password'
            value={password}
            feedback={false}
            onChange={(e) => setPassword(e.target.value)}
            toggleMask
          />
          <label htmlFor='password'>Пароль</label>
        </FloatLabel>
      </div>
      <Button
        onClick={onClick}>
        Войти
      </Button>
    </>

  )
}

const RegisterForm = () => {
  //USER
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');

  //DEALER
  const [city, setCity] = useState('');
  const [dealerName, setDealerName] = useState('');
  const [phone, setPhone] = useState('');

  const history = useHistory();

  const dispatch = useAppDispatch();

  const dealerId = useRegisterDataDealerId();

  useEffect(() => {
    history.push('#register');
  }, []);

  const onClick = () => {
    dispatch(fetchCreateDealerAction(
      {
        city: city,
        name: dealerName,
        reward: 10,
      }
    ));
  }

  useEffect(() => {
    if (dealerId) {
      dispatch(fetchCreateUserAction({
        username: username,
        full_name: fullName,
        role: 'office_employee',
        dealer_id: dealerId,
        password: password,
      }));
    }
  }, [dealerId]);

  const roles = [
    { label: 'dealer', value: 'dealer' },
    { label: 'office_employee', value: 'office_employee' },
    { label: 'production_employee', value: 'production_employee' },
    { label: 'administrator', value: 'administrator' },
    { label: 'boss_sales', value: 'boss_sales' },
  ];

  const showRoles = false;

  return (
    <div className={'auth__form p-fluid'}>
      <div className={'auth__column-form-container'}>
        Дилер
        <FloatLabel>
          <InputText
            id='dealerName'
            type={'text'}
            value={dealerName}
            onChange={(e) => setDealerName(e.target.value)}
          />
          <label htmlFor='dealerName'>Имя</label>
        </FloatLabel>
        <FloatLabel>
          <InputText
            id='city'
            type={'text'}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <label htmlFor='city'>Город</label>
        </FloatLabel>
        <FloatLabel>
          <InputText
            id='phone'
            type={'text'}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <label htmlFor='phone'>Телефон</label>
        </FloatLabel>
      </div>
      <div className={'auth__column-form-container'}>
        Пользователь
        <FloatLabel>
          <InputText
            id='username'
            type={'text'}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor='username'>Логин</label>
        </FloatLabel>
        <FloatLabel>
          <InputText
            id='full_name'
            type={'text'}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <label htmlFor='full_name'>Имя</label>
        </FloatLabel>
        {showRoles ? (
          <FloatLabel>
            <Dropdown
              id='dealer_id'
              value={role}
              options={roles}
              onChange={(e) => setRole(e.value)}
              placeholder='Select a Dealer'
            />
            <label htmlFor='dealer_id'>Роль</label>
          </FloatLabel>
        ) : null}

        <FloatLabel>
          <Password
            inputId='password'
            toggleMask
            feedback={false}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor='password'>Пароль</label>
        </FloatLabel>
      </div>

      <Button
        onClick={onClick}>
        Зарегистрироваться
      </Button>
    </div>
  );
}

const AuthPage = () => {
  const [authOrRegister, setAuthOrRegister] = useState(false);

  const history = useHistory();

  useEffect(() => {
    history.push('#login');
  }, []);

  return (
    <div className={'auth'}>
      {!authOrRegister
        ? (<AuthForm />)
        : (<RegisterForm />)
      }
      <div onClick={() => setAuthOrRegister(!authOrRegister)} className={'auth__change-form'}>
        {!authOrRegister ? ('Нет аккаунта? Зарегистрироваться') : ('Есть аккаунт? Войти')}
      </div>
    </div>
  );
};

export default AuthPage;
